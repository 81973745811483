.footer-wrapper {
  background-color: #FDF0F0;
  padding: 40px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
}

.contact-button {
  border: 2px solid #132043;
  color: #F1B4BB;
  font-weight: bold;
  padding: 10px 30px;
  border-radius: 30px;
  background: none;
  cursor: pointer;
  font-size: 16px;
}

.contact-button:hover {
  background-color: #9fdd39;
  color: #FDF0F0;
}

.subscribe-section {
  margin: 30px 0;
}

.subscribe-title {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}

.subscribe-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.subscribe-input {
  padding: 10px;
  border: 1px solid #132043;
  border-radius: 5px;
  width: 250px;
  margin-right: 10px;
}

.subscribe-button {
  background-color: #132043;
  color: #FDF0F0;
  border: none;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.subscribe-button:hover {
  background-color: #9fdd39;
}

.subscribe-success {
  opacity: 0;
  transform: scale(0.8);
  animation: fadeInScale 0.5s ease-out forwards;
  color: #F1B4BB;
  font-size: 1.5rem;
  margin-top: 10px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}


@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.privacy-text {
  font-size: 12px;
  color: #666;
  margin-top: 10px;
}

.social-icons {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.social-icons a {
  color: #132043; 
  font-size: 30px; 
  transition: color 0.3s ease-in-out;
}

.social-icons a:hover {
  color: #9fdd39; 
}

.footer-brand {
  font-size: clamp(24px, 8vw, 120px); 
  font-weight: bold;
  color: #132043;
  display: flex;
  justify-content: center;
}

.footer-brand a {
  text-decoration: none;
  color: #132043 !important; 
}

.footer-brand a:hover {
  color: #9fdd39 !important; 
}

@keyframes wave {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(14deg); }
  30% { transform: rotate(-8deg); }
  45% { transform: rotate(14deg); }
  60% { transform: rotate(-4deg); }
  75% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

.wave {
  animation: wave 1s ease-in-out;
}

.modal-title {
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.7);
  font-size: 24px; 
  font-weight: bold; 
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../../public/DumbellsWallBalls.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  max-width: 500px;
  width: 90%;
}

.modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 1;  
}

.modal-inner {
  position: relative;
  z-index: 1;
  padding: 20px 30px;
  color: #FDF0F0;  
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
}

.modal-form input,
.modal-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #000000;
  border-radius: 3px;
  color: #FDF0F0; 
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); 
  opacity: 1; 
  transition: background-color 1.5s ease;
  color: #132043; 

}

.modal-form input:focus,
.modal-form input:not(:placeholder-shown),
.modal-form textarea:focus,
.modal-form textarea:not(:placeholder-shown) {
  background-color: rgba(255, 255, 255, 0.8);
}

.modal-form textarea {
  resize: vertical;
  min-height: 100px;
}

.modal-form button {
  background-color: #132043;
  color: #FDF0F0;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modal-form button:hover {
  background-color: #9fdd39;
}